/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

export class Coordinates {
    latitude?: number
    longitude?: number
}

export class Location extends Coordinates {
    name: string
    address: string
    phones: string[]
    faxes: string[]

    constructor() {
        super();
        this.name = ''
        this.address = ''
        this.phones = ['']
        this.faxes = ['']
    }
}

export type Gender = 'FEMALE' | 'MALE';

export class Doctor {
    id?: string
    firstName: string
    lastName: string
    photoUrl?: string
    localPhotoFile?: File
    emails: string[]
    credentials: string[]
    specialties: string[]
    locations: Location[]
    advanced: boolean
    website?: string
    gender: Gender

    constructor() {
        this.firstName = ''
        this.lastName = ''
        this.emails = ['']
        this.credentials = ['']
        this.specialties = ['']
        this.locations = [new Location()]
        this.advanced = false
        this.gender = 'FEMALE'
    }
}

export type ServiceKind =
    'URGENT_CARE' |
    'LABORATORY' |
    'IMAGING_CENTER' |
    'VASCULAR_CENTER' |
    'SLEEP_CENTER';

export class ServiceProvider {
    id?: string
    name: string
    kind: ServiceKind
    location: Location
    openWeekdays: number
    closeWeekdays: number
    openWeekends: number
    closeWeekends: number

    constructor() {
        this.name = '';
        this.kind = 'URGENT_CARE';
        this.location = new Location();
        this.openWeekdays = 8;
        this.closeWeekdays = 17;
        this.openWeekends = 8;
        this.closeWeekends = 15;
    }
}

export class Post {
    id?: string
    date: Date
    imageUrl?: string
    localImageFile?: File
    title: string
    text: string
    published: boolean
    pinned: boolean

    constructor() {
        this.date = new Date();
        this.title = '';
        this.text = '';
        this.published = false;
        this.pinned = false;
    }
}


export class Condition {
    id?: string
    title: string
    text: string

    constructor() {
        this.title = '';
        this.text = '';
    }
}