/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

export const DEV_MODE = process.env.ENV === 'development';

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCCdj0V4wuXyb2joa7urKSj_MFDlq7qssU",
  authDomain: "doctor-directory-app.firebaseapp.com",
  databaseURL: "https://doctor-directory-app.firebaseio.com",
  // databaseURL: DEV_MODE
  //   ? "https://doctor-directory-dev.firebaseio.com/"
  //   : "https://doctor-directory-app.firebaseio.com",
  projectId: "doctor-directory-app",
  storageBucket: "doctor-directory-app.appspot.com",
  messagingSenderId: "838124712496",
  appId: "1:838124712496:web:52c4f0ffe2e909ef",
};

export const GOOGLE_API_KEY = 'AIzaSyD_OEZOM-fLIz_Y4imtiyRQB2T9aaC6HU0'
