/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import * as React from 'react';

import {
    Modal, Header, Form, Button, Input, TextArea,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { Condition } from '../../models/domain.models';
import { NotificationsContext } from '../../app';
import { AppState } from '../../store';
import { saveCondition, ConditionActions } from '../../actions/domain/condition.actions';
import { AsyncState } from '../../utils/async_state';

type Props = {
    condition: Condition,
    onDissmiss: () => void,
}

export const EditConditionModal = (props: Props) => {
    if (!props.condition) return null;

    const [condition, setCondition] = React.useState({ ...props.condition } as Condition)
    const dispatch = useDispatch()
    const notifications = React.useContext(NotificationsContext)
    const saveState = useSelector(
        (appState: AppState) => appState.domain.saveConditionState,
        (next, prev) => next.isEqual(prev),
    );
    const clearSaveState = () => dispatch({
        type: ConditionActions.SAVE_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (saveState.isFailed) {
            notifications.get().addFromAsyncError(saveState.error)
            clearSaveState();
        }
        if (saveState.isSuccessful) {
            props.onDissmiss()
            clearSaveState();
            notifications.get().add({
                message: 'Saved successfully',
                level: 'success',
            })
        }
    }, [saveState])

    const save = (publish: boolean) => {
        if (condition.title.length === 0)
            return notifications.get().add({ message: 'Empty title' })
        if (condition.text.length === 0)
            return notifications.get().add({ message: 'Empty text' })
        dispatch(saveCondition(condition, publish))
    }

    return (
        <Modal open size='small'
            closeOnDimmerClick={false}
            onClose={props.onDissmiss}>
            <Header content='Condition Form' />
            <Modal.Content>
                <Form size='small'>
                    <Form.Field >
                        <label>Title*</label>
                        <Input fluid size='small'
                            placeholder='Title'
                            value={condition.title}
                            onChange={(_, data) => setCondition({ ...condition, title: data.value })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Text*</label>
                        <TextArea size='small'
                            style={{ height: 200 }}
                            placeholder='Text'
                            value={condition.text}
                            onChange={(_, data) => {
                                setCondition({ ...condition, text: data.value.toString() })
                            }} />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='red'
                    onClick={props.onDissmiss}>
                    Cancel
                </Button>
                <Button
                    color='green'
                    disabled={saveState.isInProgress}
                    onClick={_ => save(false)}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
}