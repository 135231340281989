/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Card, Button, Image, Icon, Header, Segment,
} from 'semantic-ui-react';
import * as moment from 'moment';

import { AppState } from '../../store';
import {
    PickAsyncStateWidget, AsyncErrorMessage, BackLoadingParagraph,
} from '.';
import {
    laodPosts as _loadPosts,
    removePost as _removePost,
    PostActions,
} from '../../actions/domain/post.actions';
import { Post } from '../../models/domain.models';
import { NotificationsContext } from '../../app';
import { AsyncState } from '../../utils/async_state';


type Props = {
    query: string,
    onEdit: (post: Post) => void,
}

export const PostList = (props: Props) => {
    const notifications = React.useContext(NotificationsContext)
    const dispatch = useDispatch()

    const loginState = useSelector(
        (appState: AppState) => appState.auth.loginState,
        (next, prev) => next.isEqual(prev));
    const loadPosts = () => dispatch(_loadPosts())
    const loadState = useSelector(
        (s: AppState) => s.domain.loadPostsState,
        (next, prev) => next.isEqual(prev));
    React.useEffect(() => {
        if (loadState.isEmpty && loginState.isSuccessful)
            loadPosts();
    }, [loadState, loginState])
    const removeState = useSelector(
        (s: AppState) => s.domain.removePostState,
        (next, prev) => next.isEqual(prev));
    const removePost = (id: string) => {
        if (removeState.isInProgress)
            return
        if (confirm('Remove?'))
            dispatch(_removePost(id))
    }
    const clearRemovePostState = () => dispatch({
        type: PostActions.REMOVE_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (removeState.isFailed)
            notifications.get().addFromAsyncError(removeState.error)
        if (removeState.isSuccessful) {
            notifications.get().add({ message: 'Removed successfully', level: 'success' })
            clearRemovePostState()
        }
    }, [removeState])

    return PickAsyncStateWidget(loadState, {
        onProgress: _ => <BackLoadingParagraph />,
        onFail: (error) => (
            <AsyncErrorMessage error={error}
                action={{ onClick: () => loadPosts() }} />
        ),
        onValue: (_data) => {
            let data: Post[];
            if (!props.query) {
                data = _data;
            } else {
                const filter = props.query.toLowerCase()
                data = _data.filter(post =>
                    post.title.toLowerCase().includes(filter) ||
                    post.text.toLowerCase().includes(filter));
            }
            data.sort((p1, p2) => p1.pinned ? -1 : p2.pinned ? 1 :
                p2.date.getTime() - p1.date.getTime())
            if (data.length === 0)
                return (
                    <Segment basic placeholder>
                        <Header icon color='grey' size='tiny'>
                            <Icon name='search' />
                            <h4>Nothing was found</h4>
                        </Header>
                    </Segment>
                );
            return (
                <Card.Group>
                    {data.map(post => (
                        <Card key={`postCard_${post.id}_${Math.random()}`}>
                            {post.pinned === true ? (<Icon name='pin' />) : null}
                            <Image src={post.imageUrl} wrapped ui={false} className='post-image' />
                            <Card.Content>
                                <Card.Header style={{ display: 'flex', alignItems: 'start' }}>
                                    {post.title}
                                </Card.Header>
                                <Card.Meta>
                                    {post.published === true ? null :
                                        <span><Icon name='eye slash outline' color='orange' />&nbsp;&nbsp;</span>}
                                    {moment(post.date).format('MM/DD/YYYY')}
                                </Card.Meta>
                            </Card.Content>
                            <Card.Content extra>
                                <Button basic
                                    icon='trash alternate outline'
                                    color='red'
                                    size='mini'
                                    loading={removeState.isInProgress && removeState.value === post.id}
                                    onClick={() => removePost(post.id)} />
                                <Button basic
                                    color='green'
                                    size='mini'
                                    style={{ float: 'right' }}
                                    onClick={() => props.onEdit(post)}
                                >Edit</Button>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            );
        },
    })
}