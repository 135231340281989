/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

import 'semantic-ui-css/semantic.min.css'
import '../main.css'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Container, Loader } from 'semantic-ui-react'
import { Provider, useSelector} from 'react-redux'
import { initializeApp } from 'firebase'

import { createAppStore, AppState } from './store';
import { Notifications } from './views/components/notifications';
import { FIREBASE_CONFIG } from './c';
import { init } from './actions/auth.actions';
import { LoginModal } from './views/components/login.modal';
import { MainScreen } from './views/screens/main.screen';

const firebaseApp = initializeApp(FIREBASE_CONFIG)
const store = createAppStore(firebaseApp);

let notifications: Notifications;
export const NotificationsContext
    : React.Context<{ get: () => Notifications }>
    = React.createContext(null)

const AppContent = () => {
    const initState = useSelector((appState: AppState) => appState.auth.initState)
    if (initState.isNotSuccessful)
        return (
            <Container>
                <Loader size='small' active />
            </Container>
        )
    return (
        <Container>
            <Notifications ref={ref => notifications = ref} />
            <LoginModal />
            <MainScreen />
        </Container>
    )
}

const App = () => {
    return (
        <Provider store={store}>
            <NotificationsContext.Provider value={{ get: () => notifications }}>
                <AppContent />
            </NotificationsContext.Provider>
        </Provider>
    )

}

ReactDOM.render(<App />, document.getElementById('content'))

store.dispatch(init())