/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { AnyAction } from 'redux';

import { AsyncState } from '../utils/async_state';
import { AppState } from '.';
import { AuthActions } from '../actions/auth.actions';

export class AuthState {
    readonly initState: AsyncState<firebase.User> = AsyncState.empty();
    readonly loginState: AsyncState<firebase.User> = AsyncState.empty();
    readonly logoutState: AsyncState = AsyncState.empty();
}

export const authReducer = (state: AppState, action: AnyAction): AuthState => {
    switch (action.type) {
        case AuthActions.INIT_STATE_CHANGED: {
            const initState = action.state as AsyncState<firebase.User>
            return {
                ...state.auth,
                initState,
                loginState: initState.isSuccessful && initState.value
                    ? AsyncState.success(initState.value)
                    : state.auth.loginState,
            }
        }
        case AuthActions.LOGIN_STATE_CHANGED: {
            return {
                ...state.auth,
                loginState: action.state,
            }
        }
        case AuthActions.LOGOUT_STATE_CHANGED: {
            const logoutState = action.state as AsyncState
            return {
                ...state.auth,
                logoutState,
                loginState: logoutState.isSuccessful
                    ? AsyncState.empty()
                    : state.auth.loginState,
            }
        }
        default:
            return state.auth;
    }
}