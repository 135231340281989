/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Card, Button, Icon, Header, Segment,
} from 'semantic-ui-react';

import { AppState } from '../../store';
import {
    PickAsyncStateWidget, AsyncErrorMessage, BackLoadingParagraph,
} from '.';
import {
    laodConditions as _loadConditions,
    removeCondition as _removeCondition,
    ConditionActions,
} from '../../actions/domain/condition.actions';
import { Condition } from '../../models/domain.models';
import { NotificationsContext } from '../../app';
import { AsyncState } from '../../utils/async_state';


type Props = {
    query: string,
    onEdit: (condition: Condition) => void,
}

export const ConditionList = (props: Props) => {
    const notifications = React.useContext(NotificationsContext)
    const dispatch = useDispatch()

    const loginState = useSelector(
        (appState: AppState) => appState.auth.loginState,
        (next, prev) => next.isEqual(prev));
    const loadConditions = () => dispatch(_loadConditions())
    const loadState = useSelector(
        (s: AppState) => s.domain.loadConditionsState,
        (next, prev) => next.isEqual(prev));
    React.useEffect(() => {
        if (loadState.isEmpty && loginState.isSuccessful)
            loadConditions();
    }, [loadState, loginState])
    const removeState = useSelector(
        (s: AppState) => s.domain.removeConditionState,
        (next, prev) => next.isEqual(prev));
    const removeCondition = (id: string) => {
        if (removeState.isInProgress)
            return
        if (confirm('Remove?'))
            dispatch(_removeCondition(id))
    }
    const clearRemoveConditionState = () => dispatch({
        type: ConditionActions.REMOVE_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (removeState.isFailed)
            notifications.get().addFromAsyncError(removeState.error)
        if (removeState.isSuccessful) {
            notifications.get().add({ message: 'Removed successfully', level: 'success' })
            clearRemoveConditionState()
        }
    }, [removeState])

    return PickAsyncStateWidget(loadState, {
        onProgress: _ => <BackLoadingParagraph />,
        onFail: (error) => (
            <AsyncErrorMessage error={error}
                action={{ onClick: () => loadConditions() }} />
        ),
        onValue: (_data) => {
            let data: Condition[];
            if (!props.query) {
                data = _data;
            } else {
                const filter = props.query.toLowerCase()
                data = _data.filter(condition =>
                    condition.title.toLowerCase().includes(filter) ||
                    condition.text.toLowerCase().includes(filter));
            }
            if (data.length === 0)
                return (
                    <Segment basic placeholder>
                        <Header icon color='grey' size='tiny'>
                            <Icon name='search' />
                            <h4>Nothing was found</h4>
                        </Header>
                    </Segment>
                );
            return (
                <Card.Group>
                    {data.map(condition => (
                        <Card key={`conditionCard_${condition.id}_${Math.random()}`}>
                            <Card.Content>
                                <Card.Header style={{ display: 'flex', alignItems: 'start' }}>
                                    {condition.title}
                                </Card.Header>
                                <Card.Description>
                                    {condition.text.substring(0, 120)}...
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <Button basic
                                    icon='trash alternate outline'
                                    color='red'
                                    size='mini'
                                    loading={removeState.isInProgress && removeState.value === condition.id}
                                    onClick={() => removeCondition(condition.id)} />
                                <Button basic
                                    color='green'
                                    size='mini'
                                    style={{ float: 'right' }}
                                    onClick={() => props.onEdit(condition)}
                                >Edit</Button>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            );
        },
    })
}