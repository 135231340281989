/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import * as uuid from 'uuid'

import { Doctor } from "../../models/domain.models";
import { AsyncAction } from "..";
import { AsyncState, AsyncError } from "../../utils/async_state";
import { GOOGLE_API_KEY } from '../../c';


export namespace DoctorActions {
    export const SAVE_DOCTOR_STATE_CHANGED = '@domain/saveDoctorStateChanged'
    export const LOAD_DOCTORS_STATE_CHANGED = '@domain/loadDoctorsStateChanged'
    export const REMOVE_DOCTOR_STATE_CHANGED = '@domain/removeDoctorStateChanged'
    export const FIND_DOCTOR_COORDINATES_STATE_CHANGED = '@domain/findDoctorCoordinatesStateChanged'
}

export const saveDoctor = (doctor: Doctor): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: DoctorActions.SAVE_DOCTOR_STATE_CHANGED,
            state: AsyncState.inProgress(doctor),
        })
        try {
            if (doctor.localPhotoFile) {
                const fileRef = firebase.storage().ref('images').child(uuid.v4())
                await fileRef.put(doctor.localPhotoFile)
                doctor.photoUrl = await fileRef.getDownloadURL()
                doctor.localPhotoFile = null;
            }
            const ref = firebase.database().ref('doctors');
            let value = doctor
            if (doctor.id) {
                await ref.child(doctor.id).set(doctor)
            } else {
                const newRef = await ref.push(doctor)
                value = { ...doctor, id: newRef.key }
            }

            dispatch({
                type: DoctorActions.SAVE_DOCTOR_STATE_CHANGED,
                state: AsyncState.success(value),
            })
        } catch (e) {
            dispatch({
                type: DoctorActions.SAVE_DOCTOR_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const laodDoctors = (query?: string): AsyncAction =>
    async (dispatch, getState, firebase) => {
        const currentValue = getState().domain.loadDoctorsState.value
        dispatch({
            type: DoctorActions.LOAD_DOCTORS_STATE_CHANGED,
            state: AsyncState.inProgress(),
        })
        try {
            const data = await firebase.database().ref('doctors')
                .orderByKey().once('value')
            const doctors = [] as Doctor[]
            if (data.exists()) {
                data.forEach(snap => {
                    const doctor = snap.val() as Doctor
                    doctor.id = snap.key
                    if (doctor.emails == null)
                      doctor.emails = [''];
                    doctors.push(doctor)
                });
            }
            dispatch({
                type: DoctorActions.LOAD_DOCTORS_STATE_CHANGED,
                state: AsyncState.success<Doctor[]>(doctors),
            })
        } catch (e) {
            dispatch({
                type: DoctorActions.LOAD_DOCTORS_STATE_CHANGED,
                state: AsyncState.failed(e, currentValue),
            })
        }
    }


export const removeDoctor = (id: string): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: DoctorActions.REMOVE_DOCTOR_STATE_CHANGED,
            state: AsyncState.inProgress(id),
        })
        try {
            await firebase.database().ref(`doctors/${id}`).remove()
            dispatch({
                type: DoctorActions.REMOVE_DOCTOR_STATE_CHANGED,
                state: AsyncState.success(id),
            })
        } catch (e) {
            dispatch({
                type: DoctorActions.REMOVE_DOCTOR_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const findDoctorCoordinates = (doctor: Doctor, locationIndex: number): AsyncAction =>
    async (dispatch, _, __) => {
        dispatch({
            type: DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED,
            state: AsyncState.inProgress({ doctor, locationIndex }),
        })
        try {
            const query = encodeURIComponent(
                doctor.locations[locationIndex].address.replace(/\ /g, '+'));
            const uri = `https://maps.googleapis.com/maps/api/geocode/json` +
                `?key=${GOOGLE_API_KEY}&address=${query}`;
            const response = await fetch(uri)
            if (response.status !== 200) {
                dispatch({
                    type: DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED,
                    state: AsyncState.failed(AsyncError.serverError(),
                        { doctor, locationIndex }),
                })
                return;
            }
            const results = await response.json();
            if (results.results.length === 0) {
                dispatch({
                    type: DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED,
                    state: AsyncState.success({ doctor, locationIndex }),
                })
                return;
            }
            dispatch({
                type: DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED,
                state: AsyncState.success({
                    doctor,
                    locationIndex,
                    coordinates: {
                        latitude: results.results[0].geometry.location.lat,
                        longitude: results.results[0].geometry.location.lng,
                    },
                }),
            })
        } catch (e) {
            dispatch({
                type: DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED,
                state: AsyncState.failed(e, { doctor, locationIndex }),
            })
        }
    }