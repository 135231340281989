/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { AsyncState } from "../utils/async_state";
import { AsyncAction } from ".";

export namespace AuthActions {
    export const INIT_STATE_CHANGED = '@auth/initStateChanged'
    export const LOGIN_STATE_CHANGED = '@auth/loginStateChanged'
    export const LOGOUT_STATE_CHANGED = '@auth/logoutStateChanged'
}

export const init = (): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({ type: AuthActions.INIT_STATE_CHANGED, state: AsyncState.inProgress() })
        try {
            firebase.auth().onAuthStateChanged(user => {
                firebase.auth().onAuthStateChanged(() => null)
                dispatch({
                    type: AuthActions.INIT_STATE_CHANGED,
                    state: AsyncState.success<firebase.User>(user),
                })
            })
        } catch (e) {
            console.error('failed to restore session', e)
            dispatch({
                type: AuthActions.INIT_STATE_CHANGED,
                state: AsyncState.success<firebase.User>(),
            })
        }
    }

export const login = (email: string, password: string): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({ type: AuthActions.LOGIN_STATE_CHANGED, state: AsyncState.inProgress() })
        try {
            const creds = await firebase.auth()
                .signInWithEmailAndPassword(email, password)
            dispatch({
                type: AuthActions.LOGIN_STATE_CHANGED,
                state: AsyncState.success<firebase.User>(creds.user),
            })
        } catch (e) {
            dispatch({
                type: AuthActions.LOGIN_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const logout = (): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({ type: AuthActions.LOGOUT_STATE_CHANGED, state: AsyncState.inProgress() })
        try {
            await firebase.auth().signOut()
            dispatch({
                type: AuthActions.LOGOUT_STATE_CHANGED,
                state: AsyncState.success(),
            })
        } catch (e) {
            dispatch({
                type: AuthActions.LOGOUT_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }