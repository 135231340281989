/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { ServiceKind, ServiceProvider } from '../models/domain.models';

export function serviceKindToString(kind: ServiceKind) {
    if (kind === 'URGENT_CARE')
        return 'Urgent Care';
    if (kind === 'LABORATORY')
        return 'Laboratory';
    if (kind === 'IMAGING_CENTER')
        return 'Imaging Center';
    if (kind === 'VASCULAR_CENTER')
        return 'Vascular Center';
    return 'Sleep Center';
}

export function serviceProviderOpenOnWeekdays(provider: ServiceProvider) {
    const open = provider.openWeekdays <= 12
        ? `${provider.openWeekdays}am`
        : `${provider.openWeekdays - 12}pm`;
    const close = provider.closeWeekdays <= 12
        ? `${provider.closeWeekdays}am`
        : `${provider.closeWeekdays - 12}pm`;
    return `${open}-${close}`
}

export function serviceProviderOpenOnWeekends(provider: ServiceProvider) {
    const open = provider.openWeekends <= 12
        ? `${provider.openWeekends}am`
        : `${provider.openWeekends - 12}pm`;
    const close = provider.closeWeekends <= 12
        ? `${provider.closeWeekends}am`
        : `${provider.closeWeekends - 12}pm`;
    return `${open}-${close}`
}