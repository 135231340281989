/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import _ from 'lodash';
import * as React from 'react';
import { Doctor, Location, Gender } from '../../models/domain.models';
import {
    Modal, Header, Form, Button, Input, Icon, Checkbox,
    Container, TextArea, Dropdown,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsContext } from '../../app';
import { AppState } from '../../store';
import {
    saveDoctor, findDoctorCoordinates as _findCoordinates,
    DoctorActions,
} from '../../actions/domain/doctor.actions';
import { AsyncState } from '../../utils/async_state';

type Props = {
    doctor: Doctor,
    onDissmiss: () => void,
}

export const EditDoctorModal = (props: Props) => {
    if (!props.doctor) return null;

    const [doctor, setDoctor] = React.useState({ ...props.doctor } as Doctor)
    const dispatch = useDispatch()
    const notifications = React.useContext(NotificationsContext)
    const saveState = useSelector(
        (appState: AppState) => appState.domain.saveDoctorState,
        (next, prev) => next.isEqual(prev),
    );
    const findCoordinatesState = useSelector(
        (appState: AppState) => appState.domain.findDoctorCoordinatesState,
        (next, prev) => next.isEqual(prev),
    );
    const clearSaveState = () => dispatch({
        type: DoctorActions.SAVE_DOCTOR_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    const clearFindCoordinatesState = () => dispatch({
        type: DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (saveState.isFailed) {
            notifications.get().addFromAsyncError(saveState.error)
            clearSaveState();
        }
        if (saveState.isSuccessful) {
            props.onDissmiss()
            clearSaveState();
            notifications.get().add({
                message: 'Saved successfully',
                level: 'success',
            })
        }
    }, [saveState])
    React.useEffect(() => {
        if (findCoordinatesState.isFailed) {
            notifications.get().addFromAsyncError(
                findCoordinatesState.error)
        }
        if (findCoordinatesState.isSuccessful) {
            if (!findCoordinatesState.value.coordinates) {
                notifications.get().add({
                    message: 'Coordinates not found',
                    level: 'warning',
                })
                return
            }
            const idx = findCoordinatesState.value.locationIndex;
            const coordinates = findCoordinatesState.value.coordinates;
            const locations = doctor.locations.slice()
            locations[idx].latitude = coordinates.latitude;
            locations[idx].longitude = coordinates.longitude;
            setDoctor({ ...doctor, locations })
            clearFindCoordinatesState();
        }
    }, [findCoordinatesState])

    const save = () => {
        if (doctor.firstName.length === 0)
            return notifications.get().add({ message: 'Empty first name' })
        if (doctor.lastName.length === 0)
            return notifications.get().add({ message: 'Empty last name' })
        if (doctor.website && !doctor.website.startsWith('http://') &&
            !doctor.website.startsWith('https://'))
            return notifications.get().add({ message: 'Website must start with http:// or https://' })
        if (doctor.credentials.length === 0)
            return notifications.get().add({ message: 'Empty credential' })
        for (const speciality of doctor.specialties)
            if (speciality.length === 0)
                return notifications.get().add({ message: 'Empty speciality' })
        for (const location of doctor.locations) {
            if (location.name.length === 0)
                return notifications.get().add({ message: 'Empty location name' })
            if (location.address.length === 0)
                return notifications.get().add({ message: 'Empty location address' })
        }
        dispatch(saveDoctor(doctor))
    }
    const findCoordinates = (locationIndex: number) => {
        if (!doctor.locations[locationIndex].address)
            return notifications.get().add({ message: 'Enter location address' })
        dispatch(_findCoordinates(doctor, locationIndex))
    }

    let fileInputRef: HTMLInputElement;
    return (
        <Modal open size='small'
            closeOnDimmerClick={false}
            onClose={props.onDissmiss}>
            <Header content='Doctor Form' />
            <Modal.Content>
                <Form size='small'>
                    <Form.Field >
                        <label>Photo</label>
                        <input
                            ref={(ref) => fileInputRef = ref}
                            type="file"
                            hidden
                            onChange={(e) => setDoctor({
                                ...doctor,
                                localPhotoFile: e.target.files[0],
                            })}
                        />
                        <Input fluid size='small'
                            placeholder='Photo'
                            action={{
                                icon: 'file image',
                                onClick: () => fileInputRef.click(),
                            }}
                            value={doctor.localPhotoFile ? doctor.localPhotoFile.name : doctor.photoUrl}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>First Name*</label>
                        <Input fluid size='small'
                            placeholder='First Name'
                            value={doctor.firstName}
                            onChange={(_, data) => setDoctor({ ...doctor, firstName: data.value })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Last Name*</label>
                        <Input fluid size='small'
                            placeholder='Last Name'
                            value={doctor.lastName}
                            onChange={(_, data) => setDoctor({ ...doctor, lastName: data.value })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Gender</label>
                        <Dropdown
                            placeholder='Select Friend'
                            fluid
                            selection
                            value={doctor.gender}
                            options={[{
                                key: 'FEMALE',
                                value: 'FEMALE',
                                text: 'Female',
                            }, {
                                key: 'MALE',
                                value: 'MALE',
                                text: 'Male',
                            }]}
                            onChange={(_, data) => setDoctor({
                                ...doctor,
                                gender: data.value as Gender,
                            })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Website</label>
                        <Input fluid size='small'
                            placeholder='Website'
                            value={doctor.website || ''}
                            onChange={(_, data) => setDoctor({ ...doctor, website: data.value })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Emails</label>
                        {doctor.emails.map((email, idx) => (
                            <Input size='small' fluid
                                placeholder={`Email ${idx + 1}`}
                                key={`email_${idx}`}
                                style={{ paddingBottom: 2 }}
                                action={idx === 0
                                    ? {
                                        icon: 'add',
                                        onClick: () => setDoctor({
                                            ...doctor,
                                            emails: doctor.emails.concat(''),
                                        }),
                                    }
                                    : {
                                        icon: 'minus',
                                        onClick: () => setDoctor({
                                            ...doctor,
                                            emails: doctor.emails.slice(0, idx)
                                                .concat(doctor.emails.slice(idx + 1)),
                                        }),
                                    }}
                                value={email}
                                onChange={(_, data) => {
                                    const emails = doctor.emails.slice()
                                    emails[idx] = data.value
                                    setDoctor({ ...doctor, emails })
                                }} />

                        ))}
                    </Form.Field>
                    <Form.Field >
                        <label>Credentials*</label>
                        {doctor.credentials.map((credential, idx) => (
                            <Input size='small' fluid
                                placeholder={`Credential ${idx + 1}`}
                                key={`credential_${idx}`}
                                style={{ paddingBottom: 2 }}
                                action={idx === 0
                                    ? {
                                        icon: 'add',
                                        onClick: () => setDoctor({
                                            ...doctor,
                                            credentials: doctor.credentials.concat(''),
                                        }),
                                    }
                                    : {
                                        icon: 'minus',
                                        onClick: () => setDoctor({
                                            ...doctor,
                                            credentials: doctor.credentials.slice(0, idx)
                                                .concat(doctor.credentials.slice(idx + 1)),
                                        }),
                                    }}
                                value={credential}
                                onChange={(_, data) => {
                                    const credentials = doctor.credentials.slice()
                                    credentials[idx] = data.value
                                    setDoctor({ ...doctor, credentials })
                                }} />

                        ))}
                    </Form.Field>
                    <Form.Field >
                        <label>Specialties*</label>
                        {doctor.specialties.map((speciality, idx) => (
                            <Form.Group widths='equal' style={{ margin: 0 }}>
                                <Input size='small' fluid
                                    key={`speciality_${idx}`}
                                    placeholder={`Speciality ${idx + 1}`}
                                    style={{ paddingBottom: 2 }}
                                    action={idx === 0
                                        ? {
                                            icon: 'add',
                                            onClick: () => setDoctor({
                                                ...doctor,
                                                specialties: doctor.specialties.concat(''),
                                            }),
                                        }
                                        : {
                                            icon: 'minus',
                                            onClick: () => setDoctor({
                                                ...doctor,
                                                specialties: doctor.specialties.slice(0, idx)
                                                    .concat(doctor.specialties.slice(idx + 1)),
                                            }),
                                        }}
                                    value={speciality}
                                    onChange={(_, data) => {
                                        const specialties = doctor.specialties.slice()
                                        specialties[idx] = data.value
                                        setDoctor({ ...doctor, specialties })
                                    }} />
                                {doctor.specialties.length > 1
                                    ? <div style={{ paddingLeft: 10, display: 'grid' }}>
                                        <Icon name='angle up'
                                            color='grey'
                                            size='large'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                let specialties: string[];
                                                if (idx === 0) {
                                                    specialties = doctor.specialties.slice(1)
                                                        .concat(doctor.specialties[0])
                                                } else {
                                                    specialties = doctor.specialties.slice();
                                                    const tmp = specialties[idx]
                                                    specialties[idx] = specialties[idx - 1]
                                                    specialties[idx - 1] = tmp
                                                }
                                                setDoctor({ ...doctor, specialties });
                                            }} />
                                        <Icon name='angle down'
                                            color='grey'
                                            size='large'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                let specialties: string[];
                                                if (idx === doctor.specialties.length - 1) {
                                                    specialties = [doctor.specialties[idx]]
                                                        .concat(doctor.specialties.slice(0, idx))
                                                } else {
                                                    specialties = doctor.specialties.slice();
                                                    const tmp = specialties[idx]
                                                    specialties[idx] = specialties[idx + 1]
                                                    specialties[idx + 1] = tmp
                                                }
                                                setDoctor({ ...doctor, specialties });
                                            }} />
                                    </div>
                                    : null}
                            </Form.Group>
                        ))}
                    </Form.Field>
                    <Form.Field >
                        <label>Locations*</label>
                        {doctor.locations.map((location, idx) => (
                            <Container key={`location_${idx}`}>
                                <Form.Group widths='equal' style={{ margin: 0 }}>
                                    <Input size='small' fluid
                                        style={{ paddingBottom: 2 }}
                                        placeholder={`Location Name ${idx + 1}`}
                                        action={idx === 0
                                            ? {
                                                icon: 'add',
                                                onClick: () => setDoctor({
                                                    ...doctor,
                                                    locations: doctor.locations.concat(new Location()),
                                                }),
                                            }
                                            : {
                                                icon: 'minus',
                                                onClick: () => setDoctor({
                                                    ...doctor,
                                                    locations: doctor.locations.slice(0, idx)
                                                        .concat(doctor.locations.slice(idx + 1)),
                                                }),
                                            }}
                                        value={location.name}
                                        onChange={(_, data) => {
                                            const locations = doctor.locations.slice()
                                            locations[idx].name = data.value
                                            setDoctor({ ...doctor, locations })
                                        }} />
                                    {doctor.locations.length > 1
                                        ? <div style={{ paddingLeft: 10, display: 'grid' }}>
                                            <Icon name='angle up'
                                                color='grey'
                                                size='large'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    let locations: Location[];
                                                    if (idx === 0) {
                                                        locations = doctor.locations.slice(1)
                                                            .concat(doctor.locations[0])
                                                    } else {
                                                        locations = doctor.locations.slice();
                                                        const tmp = locations[idx]
                                                        locations[idx] = locations[idx - 1]
                                                        locations[idx - 1] = tmp
                                                    }
                                                    setDoctor({ ...doctor, locations });
                                                }} />
                                            <Icon name='angle down'
                                                color='grey'
                                                size='large'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    let locations: Location[];
                                                    if (idx === doctor.locations.length - 1) {
                                                        locations = [doctor.locations[idx]]
                                                            .concat(doctor.locations.slice(0, idx))
                                                    } else {
                                                        locations = doctor.locations.slice();
                                                        const tmp = locations[idx]
                                                        locations[idx] = locations[idx + 1]
                                                        locations[idx + 1] = tmp
                                                    }
                                                    setDoctor({ ...doctor, locations });
                                                }} />
                                        </div>
                                        : null}
                                </Form.Group>
                                <Container style={{ paddingTop: 10, paddingLeft: 20, paddingBottom: 20 }}>
                                    <Form.Field>
                                        <label>Address*</label>
                                        <TextArea size='small'
                                            placeholder={`Location Address ${idx + 1}`}
                                            value={location.address}
                                            onChange={(_, data) => {
                                                const locations = doctor.locations.slice()
                                                locations[idx].address = data.value.toString()
                                                setDoctor({ ...doctor, locations })
                                            }} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Coordinates</label>
                                        <Form.Group widths='equal'>
                                            <div style={{ width: 20 }} />
                                            <Input size='small'
                                                fluid={false}
                                                placeholder='Latitude'
                                                value={location.latitude || ''}
                                                onChange={(_, data) => {
                                                    const locations = doctor.locations.slice()
                                                    locations[idx].latitude = parseFloat(data.value) || null;
                                                    setDoctor({ ...doctor, locations })
                                                }} />
                                            <div style={{ width: 30 }} />
                                            <Input size='small'
                                                fluid={false}
                                                placeholder='Longitude'
                                                value={location.longitude || ''}
                                                onChange={(_, data) => {
                                                    const locations = doctor.locations.slice()
                                                    locations[idx].longitude = parseFloat(data.value) || null;
                                                    setDoctor({ ...doctor, locations })
                                                }} />
                                            <div style={{ width: 30 }} />
                                            <Button icon size='small'
                                                disabled={findCoordinatesState.isInProgress}
                                                loading={findCoordinatesState.isInProgress
                                                    && findCoordinatesState.value.locationIndex === idx}
                                                onClick={() => findCoordinates(idx)}>
                                                <Icon name='bullseye' />
                                            </Button>
                                            <div style={{ width: 10 }} />
                                            <Button icon size='small'
                                                onClick={() => {
                                                    const query = encodeURIComponent(
                                                        location.address.replace(/\ /g, '+'));
                                                    const coords = `${location.latitude},${location.longitude}`;
                                                    open(`https://www.google.com/maps/place/${query}/@${coords},15z`)
                                                }}>
                                                <Icon name='map pin' />
                                            </Button>
                                        </Form.Group>
                                    </Form.Field>
                                    <Form.Field >
                                        <label>Phone</label>
                                        {location.phones.map((phone, phoneIdx) => (
                                            <Input size='small' fluid
                                                key={`phone_${idx}_${phoneIdx}`}
                                                placeholder={`Phone ${phoneIdx + 1}`}
                                                style={{ paddingBottom: 2 }}
                                                action={phoneIdx === 0
                                                    ? {
                                                        icon: 'add',
                                                        onClick: () => {
                                                            location.phones.push('')
                                                            setDoctor({ ...doctor })
                                                        },
                                                    }
                                                    : {
                                                        icon: 'minus',
                                                        onClick: () => {
                                                            location.phones.splice(phoneIdx, 1)
                                                            setDoctor({ ...doctor })
                                                        },
                                                    }}
                                                value={phone}
                                                onChange={(_, data) => {
                                                    location.phones[phoneIdx] = data.value
                                                    setDoctor({ ...doctor })
                                                }} />

                                        ))}
                                    </Form.Field>
                                    <Form.Field >
                                        <label>Fax</label>
                                        {location.faxes.map((fax, faxIdx) => (
                                            <Input size='small' fluid
                                                key={`fax_${idx}_${faxIdx}`}
                                                placeholder={`Fax ${faxIdx + 1}`}
                                                style={{ paddingBottom: 2 }}
                                                action={faxIdx === 0
                                                    ? {
                                                        icon: 'add',
                                                        onClick: () => {
                                                            location.faxes.push('')
                                                            setDoctor({ ...doctor })
                                                        },
                                                    }
                                                    : {
                                                        icon: 'minus',
                                                        onClick: () => {
                                                            location.faxes.splice(faxIdx, 1)
                                                            setDoctor({ ...doctor })
                                                        },
                                                    }}
                                                value={fax}
                                                onChange={(_, data) => {
                                                    location.faxes[faxIdx] = data.value
                                                    setDoctor({ ...doctor })
                                                }} />

                                        ))}
                                    </Form.Field>
                                </Container>
                            </Container>
                        ))}
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Checkbox
                    style={{
                        float: 'left', marginTop: 10, marginLeft: 10,
                    }}
                    label='Advanced'
                    checked={doctor.advanced}
                    onChange={(_, data) => setDoctor({
                        ...doctor,
                        advanced: data.checked,
                    })} />
                <Button
                    color='red'
                    onClick={props.onDissmiss}>
                    Cancel
                </Button>
                <Button
                    color='green'
                    loading={saveState.isInProgress}
                    disabled={saveState.isInProgress}
                    onClick={save}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
}