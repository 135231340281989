/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import uuid = require("uuid");

import { Post } from "../../models/domain.models";
import { AsyncAction } from "..";
import { AsyncState } from "../../utils/async_state";


export namespace PostActions {
    export const SAVE_STATE_CHANGED = '@domain/savePostStateChanged'
    export const LOAD_STATE_CHANGED = '@domain/loadPostsStateChanged'
    export const REMOVE_STATE_CHANGED = '@domain/removePostStateChanged'
}

export const savePost = (post: Post, publish: boolean = false): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: PostActions.SAVE_STATE_CHANGED,
            state: AsyncState.inProgress(post),
        })
        try {
            if (post.localImageFile) {
                const fileRef = firebase.storage().ref('images').child(uuid.v4())
                await fileRef.put(post.localImageFile)
                post.imageUrl = await fileRef.getDownloadURL()
                post.localImageFile = null;
            }
            const ref = firebase.database().ref('posts');
            let value = {
                ...post,
                published: publish ? true : post.published,
            }
            const dbValue = {
                ...value,
                date: post.date.toISOString(),
            }
            if (post.id) {
                await ref.child(post.id).set(dbValue);
            } else {
                const newRef = await ref.push(dbValue)
                value = { ...post, id: newRef.key }
            }
            dispatch({
                type: PostActions.SAVE_STATE_CHANGED,
                state: AsyncState.success(value),
            })
        } catch (e) {
            dispatch({
                type: PostActions.SAVE_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const laodPosts = (): AsyncAction =>
    async (dispatch, getState, firebase) => {
        const currentValue = getState().domain.loadPostsState.value
        dispatch({
            type: PostActions.LOAD_STATE_CHANGED,
            state: AsyncState.inProgress(),
        })
        try {
            const data = await firebase.database().ref('posts')
                .orderByKey().once('value')
            const posts = [] as Post[]
            if (data.exists()) {
                data.forEach(snap => {
                    const post = snap.val() as Post
                    post.id = snap.key
                    post.date = new Date(post.date);
                    posts.push(post)
                });
            }
            dispatch({
                type: PostActions.LOAD_STATE_CHANGED,
                state: AsyncState.success<Post[]>(posts),
            })
        } catch (e) {
            dispatch({
                type: PostActions.LOAD_STATE_CHANGED,
                state: AsyncState.failed(e, currentValue),
            })
        }
    }

export const removePost = (id: string): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: PostActions.REMOVE_STATE_CHANGED,
            state: AsyncState.inProgress(id),
        })
        try {
            await firebase.database().ref(`posts/${id}`).remove()
            dispatch({
                type: PostActions.REMOVE_STATE_CHANGED,
                state: AsyncState.success(id),
            })
        } catch (e) {
            dispatch({
                type: PostActions.REMOVE_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }