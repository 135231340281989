/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, Header, Form, Button, Input, Icon, Checkbox,
    Container, TextArea, Dropdown,
} from 'semantic-ui-react';

import { ServiceProvider, ServiceKind } from '../../models/domain.models';
import { NotificationsContext } from '../../app';
import { AppState } from '../../store';
import {
    saveServiceProvider, findServiceProviderCoordinates as _findCoordinates,
    ServiceProviderActions,
} from '../../actions/domain/service-provider.actions';
import { AsyncState } from '../../utils/async_state';
import { serviceKindToString } from '../../utils/domain.utils';

type Props = {
    provider: ServiceProvider,
    onDissmiss: () => void,
}
const typeDropdownOptions = ([
    'URGENT_CARE',
    'LABORATORY',
    'IMAGING_CENTER',
    'VASCULAR_CENTER',
    'SLEEP_CENTER'] as ServiceKind[])
    .map(key => ({
        key,
        value: key,
        text: serviceKindToString(key),
    }))
const ampmDropdownOptions = [
    { key: 'am', text: 'AM', value: 'am' },
    { key: 'pm', text: 'PM', value: 'pm' },
];

export const EditServiceProviderModal = (props: Props) => {
    if (!props.provider) return null;

    const [provider, setProvider] = React.useState({ ...props.provider } as ServiceProvider)
    const [openTime, setOpenTime] = React.useState({
        openWeekdaysTime: provider.openWeekdays <= 12
            ? provider.openWeekdays : provider.openWeekdays - 12,
        openWeekdaysAmpm: provider.openWeekdays <= 12
            ? 'am' : 'pm',
        closeWeekdaysTime: provider.closeWeekdays <= 12
            ? provider.closeWeekdays : provider.closeWeekdays - 12,
        closeWeekdaysAmpm: provider.closeWeekdays <= 12
            ? 'am' : 'pm',
        openWeekendsTime: provider.openWeekends <= 12
            ? provider.openWeekends : provider.openWeekends - 12,
        openWeekendsAmpm: provider.openWeekends <= 12
            ? 'am' : 'pm',
        closeWeekendsTime: provider.closeWeekends <= 12
            ? provider.closeWeekends : provider.closeWeekends - 12,
        closeWeekendsAmpm: provider.closeWeekends <= 12
            ? 'am' : 'pm',
    })
    const dispatch = useDispatch()
    const notifications = React.useContext(NotificationsContext)

    const saveState = useSelector(
        (appState: AppState) => appState.domain.saveServiceProviderState,
        (next, prev) => next.isEqual(prev),
    );
    const findCoordinatesState = useSelector(
        (appState: AppState) => appState.domain.findServiceProviderCoordinatesState,
        (next, prev) => next.isEqual(prev),
    );
    const clearSaveState = () => dispatch({
        type: ServiceProviderActions.SAVE_SERVICE_PROVIDER_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    const clearFindCoordinatesState = () => dispatch({
        type: ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (saveState.isFailed) {
            notifications.get().addFromAsyncError(saveState.error)
            clearSaveState();
        }
        if (saveState.isSuccessful) {
            props.onDissmiss()
            clearSaveState();
            notifications.get().add({
                message: 'Saved successfully',
                level: 'success',
            })
        }
    }, [saveState])
    React.useEffect(() => {
        if (findCoordinatesState.isFailed) {
            notifications.get().addFromAsyncError(
                findCoordinatesState.error)
        }
        if (findCoordinatesState.isSuccessful) {
            if (!findCoordinatesState.value.coordinates) {
                notifications.get().add({
                    message: 'Coordinates not found',
                    level: 'warning',
                })
                return
            }
            const coordinates = findCoordinatesState.value.coordinates;
            const location = {
                ...provider.location,
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
            };
            setProvider({ ...provider, location })
            clearFindCoordinatesState();
        }
    }, [findCoordinatesState])

    const save = () => {
        if (provider.name.length === 0)
            return notifications.get().add({ message: 'Empty name' })
        if (provider.location.name.length === 0)
            return notifications.get().add({ message: 'Empty location name' })
        if (provider.location.address.length === 0)
            return notifications.get().add({ message: 'Empty location address' })
        if (openTime.openWeekdaysTime > 12)
            return notifications.get().add({ message: 'Incorrect open on weekdays time' })
        if (openTime.closeWeekdaysTime > 12)
            return notifications.get().add({ message: 'Incorrect close on weekdays time' })
        if (openTime.openWeekendsTime > 12)
            return notifications.get().add({ message: 'Incorrect open on weekends time' })
        if (openTime.closeWeekendsTime > 12)
            return notifications.get().add({ message: 'Incorrect close on weekends time' })
        provider.openWeekdays = openTime.openWeekdaysTime +
            (openTime.openWeekdaysAmpm === 'am' ? 0 : 12);
        provider.closeWeekdays = openTime.closeWeekdaysTime +
            (openTime.closeWeekdaysAmpm === 'am' ? 0 : 12);
        provider.openWeekends = openTime.openWeekendsTime +
            (openTime.openWeekendsAmpm === 'am' ? 0 : 12);
        provider.closeWeekends = openTime.closeWeekendsTime +
            (openTime.closeWeekendsAmpm === 'am' ? 0 : 12);
        dispatch(saveServiceProvider(provider))
    }
    const findCoordinates = () => {
        if (!provider.location.address)
            return notifications.get().add({ message: 'Enter location address' })
        dispatch(_findCoordinates(provider))
    }

    return (
        <Modal open size='small'
            closeOnDimmerClick={false}
            onClose={props.onDissmiss}>
            <Header content='Service Provider Form' />
            <Modal.Content>
                <Form size='small'>
                    <Form.Field >
                        <label>Name*</label>
                        <Input fluid size='small'
                            placeholder='First Name'
                            value={provider.name}
                            onChange={(_, data) => setProvider({ ...provider, name: data.value })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Type*</label>
                        <Dropdown fluid selection
                            options={typeDropdownOptions}
                            value={provider.kind}
                            onChange={(e, data) => setProvider({
                                ...provider,
                                kind: data.value as ServiceKind,
                            })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Open on Weekdays*</label>
                        <Form.Group widths='equal'>
                            <div style={{ width: 20 }} />
                            <Input
                                placeholder='Open'
                                value={openTime.openWeekdaysTime}
                                label={
                                    <Dropdown
                                        value={openTime.openWeekdaysAmpm}
                                        options={ampmDropdownOptions}
                                        onChange={(e, data) => setOpenTime({
                                            ...openTime,
                                            openWeekdaysAmpm: data.value as any,
                                        })}
                                    />}
                                labelPosition='right'
                                onChange={(e, data) => {
                                    setOpenTime({
                                        ...openTime,
                                        openWeekdaysTime: parseInt(data.value) || 0,
                                    })
                                }}
                                onFocus={e => e.target.select()}
                            />
                            <div style={{ width: 20 }} />
                            <Input
                                placeholder='Close'
                                value={openTime.closeWeekdaysTime}
                                label={
                                    <Dropdown
                                        value={openTime.closeWeekdaysAmpm}
                                        options={ampmDropdownOptions}
                                        onChange={(e, data) => setOpenTime({
                                            ...openTime,
                                            closeWeekdaysAmpm: data.value as any,
                                        })}
                                    />}
                                labelPosition='right'
                                onChange={(e, data) => {
                                    setOpenTime({
                                        ...openTime,
                                        closeWeekdaysTime: parseInt(data.value) || 0,
                                    })
                                }}
                                onFocus={e => e.target.select()}
                            />
                            <div style={{ width: 20 }} />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field>
                        <label>Open on Weekends*</label>
                        <Form.Group widths='equal'>
                            <div style={{ width: 20 }} />
                            <Input
                                placeholder='Open'
                                value={openTime.openWeekendsTime}
                                label={
                                    <Dropdown
                                        value={openTime.openWeekendsAmpm}
                                        options={ampmDropdownOptions}
                                        onChange={(e, data) => setOpenTime({
                                            ...openTime,
                                            openWeekendsAmpm: data.value as any,
                                        })}
                                    />}
                                labelPosition='right'
                                onChange={(e, data) => {
                                    setOpenTime({
                                        ...openTime,
                                        openWeekendsTime: parseInt(data.value) || 0,
                                    })
                                }}
                                onFocus={e => e.target.select()}
                            />
                            <div style={{ width: 20 }} />
                            <Input
                                placeholder='Close'
                                value={openTime.closeWeekendsTime}
                                label={
                                    <Dropdown
                                        value={openTime.closeWeekendsAmpm}
                                        options={ampmDropdownOptions}
                                        onChange={(e, data) => setOpenTime({
                                            ...openTime,
                                            closeWeekendsAmpm: data.value as any,
                                        })}
                                    />}
                                labelPosition='right'
                                onChange={(e, data) => {
                                    setOpenTime({
                                        ...openTime,
                                        closeWeekendsTime: parseInt(data.value) || 0,
                                    })
                                }}
                                onFocus={e => e.target.select()}
                            />
                            <div style={{ width: 20 }} />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field >
                        <label>Location*</label>
                        <Container>
                            <Input size='small' fluid
                                style={{ paddingBottom: 2 }}
                                placeholder={`Location Name*`}
                                value={provider.location.name}
                                onChange={(_, data) => {
                                    setProvider({
                                        ...provider,
                                        location: {
                                            ...provider.location,
                                            name: data.value,
                                        },
                                    })
                                }} />
                            <Container style={{ paddingTop: 10, paddingLeft: 20, paddingBottom: 20 }}>
                                <Form.Field>
                                    <label>Address*</label>
                                    <TextArea size='small'
                                        placeholder={`Location Address*`}
                                        value={provider.location.address}
                                        onChange={(_, data) => {
                                            setProvider({
                                                ...provider,
                                                location: {
                                                    ...provider.location,
                                                    address: data.value.toString(),
                                                },
                                            })
                                        }} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Coordinates</label>
                                    <Form.Group widths='equal'>
                                        <div style={{ width: 20 }} />
                                        <Input size='small'
                                            fluid={false}
                                            placeholder='Latitude'
                                            value={provider.location.latitude || ''}
                                            onChange={(_, data) => {
                                                setProvider({
                                                    ...provider,
                                                    location: {
                                                        ...provider.location,
                                                        latitude: parseFloat(data.value) || null,
                                                    },
                                                })
                                            }} />
                                        <div style={{ width: 30 }} />
                                        <Input size='small'
                                            fluid={false}
                                            placeholder='Longitude'
                                            value={provider.location.longitude || ''}
                                            onChange={(_, data) => {
                                                setProvider({
                                                    ...provider,
                                                    location: {
                                                        ...provider.location,
                                                        longitude: parseFloat(data.value) || null,
                                                    },
                                                })
                                            }} />
                                        <div style={{ width: 30 }} />
                                        <Button icon size='small'
                                            disabled={findCoordinatesState.isInProgress}
                                            loading={findCoordinatesState.isInProgress}
                                            onClick={() => findCoordinates()}>
                                            <Icon name='bullseye' />
                                        </Button>
                                        <div style={{ width: 10 }} />
                                        <Button icon size='small'
                                            onClick={() => {
                                                const query = encodeURIComponent(
                                                    provider.location.address.replace(/\ /g, '+'));
                                                const coords =
                                                    `${provider.location.latitude},${provider.location.longitude}`;
                                                open(`https://www.google.com/maps/place/${query}/@${coords},15z`)
                                            }}>
                                            <Icon name='map pin' />
                                        </Button>
                                    </Form.Group>
                                </Form.Field>
                                <Form.Field >
                                    <label>Phone</label>
                                    {provider.location.phones.map((phone, phoneIdx) => (
                                        <Input size='small' fluid
                                            key={`phone_${phoneIdx}`}
                                            placeholder={`Phone ${phoneIdx + 1}`}
                                            style={{ paddingBottom: 2 }}
                                            action={phoneIdx === 0
                                                ? {
                                                    icon: 'add',
                                                    onClick: () => {
                                                        provider.location.phones.push('')
                                                        setProvider({ ...provider })
                                                    },
                                                }
                                                : {
                                                    icon: 'minus',
                                                    onClick: () => {
                                                        provider.location.phones.splice(phoneIdx, 1)
                                                        setProvider({ ...provider })
                                                    },
                                                }}
                                            value={phone}
                                            onChange={(_, data) => {
                                                provider.location.phones[phoneIdx] = data.value
                                                setProvider({ ...provider })
                                            }} />

                                    ))}
                                </Form.Field>
                                <Form.Field >
                                    <label>Fax</label>
                                    {provider.location.faxes.map((fax, faxIdx) => (
                                        <Input size='small' fluid
                                            key={`fax_${faxIdx}`}
                                            placeholder={`Fax ${faxIdx + 1}`}
                                            style={{ paddingBottom: 2 }}
                                            action={faxIdx === 0
                                                ? {
                                                    icon: 'add',
                                                    onClick: () => {
                                                        provider.location.faxes.push('')
                                                        setProvider({ ...provider })
                                                    },
                                                }
                                                : {
                                                    icon: 'minus',
                                                    onClick: () => {
                                                        provider.location.faxes.splice(faxIdx, 1)
                                                        setProvider({ ...provider })
                                                    },
                                                }}
                                            value={fax}
                                            onChange={(_, data) => {
                                                provider.location.faxes[faxIdx] = data.value
                                                setProvider({ ...provider })
                                            }} />

                                    ))}
                                </Form.Field>
                            </Container>
                        </Container>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='red'
                    onClick={props.onDissmiss}>
                    Cancel
                </Button>
                <Button
                    color='green'
                    loading={saveState.isInProgress}
                    disabled={saveState.isInProgress}
                    onClick={save}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
}