/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import * as React from 'react';

import {
    Modal, Header, Form, Button, Input, TextArea, Checkbox,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment = require('moment');

import { Post } from '../../models/domain.models';
import { NotificationsContext } from '../../app';
import { AppState } from '../../store';
import { savePost, PostActions } from '../../actions/domain/post.actions';
import { AsyncState } from '../../utils/async_state';

type Props = {
    post: Post,
    onDissmiss: () => void,
}

export const EditPostModal = (props: Props) => {
    if (!props.post) return null;

    const [post, setPost] = React.useState({ ...props.post } as Post)
    const dispatch = useDispatch()
    const notifications = React.useContext(NotificationsContext)
    const saveState = useSelector(
        (appState: AppState) => appState.domain.savePostState,
        (next, prev) => next.isEqual(prev),
    );
    const clearSaveState = () => dispatch({
        type: PostActions.SAVE_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (saveState.isFailed) {
            notifications.get().addFromAsyncError(saveState.error)
            clearSaveState();
        }
        if (saveState.isSuccessful) {
            props.onDissmiss()
            clearSaveState();
            notifications.get().add({
                message: 'Saved successfully',
                level: 'success',
            })
        }
    }, [saveState])

    const save = (publish: boolean) => {
        if (post.title.length === 0)
            return notifications.get().add({ message: 'Empty title' })
        if (post.text.length === 0)
            return notifications.get().add({ message: 'Empty text' })
        dispatch(savePost(post, publish))
    }

    let fileInputRef: HTMLInputElement;
    return (
        <Modal open size='small'
            closeOnDimmerClick={false}
            onClose={props.onDissmiss}>
            <Header content='Post Form' />
            <Modal.Content>
                <Form size='small'>
                    <Form.Field >
                        <label>Image</label>
                        <input
                            ref={(ref) => fileInputRef = ref}
                            type="file"
                            hidden
                            onChange={(e) => setPost({
                                ...post,
                                localImageFile: e.target.files[0],
                            })}
                        />
                        <Input fluid size='small'
                            placeholder='Image'
                            action={{
                                icon: 'file image',
                                onClick: () => fileInputRef.click(),
                            }}
                            value={post.localImageFile ? post.localImageFile.name : post.imageUrl}
                            onChange={(_, data) => {
                                setPost({ ...post, imageUrl: data.value, localImageFile: null })
                            }}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Title*</label>
                        <Input fluid size='small'
                            placeholder='Title'
                            value={post.title}
                            onChange={(_, data) => setPost({ ...post, title: data.value })}
                        />
                    </Form.Field>
                    <Form.Field >
                        <label>Text*</label>
                        <TextArea size='small'
                            style={{ height: 200 }}
                            placeholder='Text'
                            value={post.text}
                            onChange={(_, data) => {
                                setPost({ ...post, text: data.value.toString() })
                            }} />
                    </Form.Field>
                    <Form.Field >
                        <label>Date*</label>
                        <Input fluid size='small'
                            placeholder='Date'
                            value={moment(post.date).format('YYYY-MM-DD')}
                            type='date'
                            onChange={(_, data) => setPost({
                                ...post,
                                date: moment(data.value, 'YYYY-MM-DD').toDate(),
                            })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Checkbox
                    style={{
                        float: 'left', marginTop: 10, marginLeft: 10,
                    }}
                    label='Pin'
                    checked={post.pinned}
                    onChange={(_, data) => setPost({
                        ...post,
                        pinned: data.checked,
                    })} />
                <Button
                    color='red'
                    onClick={props.onDissmiss}>
                    Cancel
                </Button>
                <Button
                    color='green'
                    disabled={saveState.isInProgress}
                    onClick={_ => save(false)}>
                    Save
                </Button>
                {post.published === true ? null : (
                    <Button
                        color='blue'
                        disabled={saveState.isInProgress}
                        onClick={_ => save(true)}>
                        Publish
                </Button>)}
            </Modal.Actions>
        </Modal>
    );
}