/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { createStore, Store, applyMiddleware } from "redux";
import thunk from 'redux-thunk'

import { AuthState, authReducer } from "./auth.state";
import { DomainState, domainReducer } from "./domain.state";
import { DEV_MODE } from "../c";


export class AppState {
    readonly auth: AuthState = new AuthState();
    readonly domain: DomainState = new DomainState();
}

const appReducer = (state: AppState = new AppState(), action: any): AppState => {
    if (DEV_MODE)
        console.log('handle action', action.type, action)
    return {
        auth: authReducer(state, action),
        domain: domainReducer(state, action),
    }
}

export function createAppStore(firebase: firebase.app.App): Store<AppState, any> {
    return createStore(
        appReducer,
        applyMiddleware(thunk.withExtraArgument(firebase)),
    )
}