/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import uuid = require("uuid");

import { Condition } from "../../models/domain.models";
import { AsyncAction } from "..";
import { AsyncState } from "../../utils/async_state";


export namespace ConditionActions {
    export const SAVE_STATE_CHANGED = '@domain/saveConditionStateChanged'
    export const LOAD_STATE_CHANGED = '@domain/loadConditionsStateChanged'
    export const REMOVE_STATE_CHANGED = '@domain/removeConditionStateChanged'
}

export const saveCondition = (condition: Condition, publish: boolean = false): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: ConditionActions.SAVE_STATE_CHANGED,
            state: AsyncState.inProgress(condition),
        })
        try {
            const ref = firebase.database().ref('conditions');
            let value = { ...condition }
            if (condition.id) {
                await ref.child(condition.id).set(value);
            } else {
                const newRef = await ref.push(value)
                value = { ...condition, id: newRef.key }
            }
            dispatch({
                type: ConditionActions.SAVE_STATE_CHANGED,
                state: AsyncState.success(value),
            })
        } catch (e) {
            dispatch({
                type: ConditionActions.SAVE_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const laodConditions = (): AsyncAction =>
    async (dispatch, getState, firebase) => {
        const currentValue = getState().domain.loadConditionsState.value
        dispatch({
            type: ConditionActions.LOAD_STATE_CHANGED,
            state: AsyncState.inProgress(),
        })
        try {
            const data = await firebase.database().ref('conditions')
                .orderByKey().once('value')
            const conditions = [] as Condition[]
            if (data.exists()) {
                data.forEach(snap => {
                    const condition = snap.val() as Condition
                    condition.id = snap.key
                    conditions.push(condition)
                });
            }
            dispatch({
                type: ConditionActions.LOAD_STATE_CHANGED,
                state: AsyncState.success<Condition[]>(conditions),
            })
        } catch (e) {
            dispatch({
                type: ConditionActions.LOAD_STATE_CHANGED,
                state: AsyncState.failed(e, currentValue),
            })
        }
    }

export const removeCondition = (id: string): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: ConditionActions.REMOVE_STATE_CHANGED,
            state: AsyncState.inProgress(id),
        })
        try {
            await firebase.database().ref(`conditions/${id}`).remove()
            dispatch({
                type: ConditionActions.REMOVE_STATE_CHANGED,
                state: AsyncState.success(id),
            })
        } catch (e) {
            dispatch({
                type: ConditionActions.REMOVE_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }