/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Card, Button, Image, Label, Icon, Container, Header, Segment, Pagination,
} from 'semantic-ui-react';

import { AppState } from '../../store';
import {
    PickAsyncStateWidget, AsyncErrorMessage, BackLoadingParagraph,
} from '.';
import {
    laodDoctors as _loadDoctors,
    removeDoctor as _removeDoctor,
    DoctorActions,
} from '../../actions/domain/doctor.actions';
import { Doctor } from '../../models/domain.models';
import { NotificationsContext } from '../../app';
import { AsyncState } from '../../utils/async_state';

const ITEMS_PER_PAGE = 30;

type Props = {
    query: string,
    onEdit: (doctor: Doctor) => void,
}

export const DoctorList = (props: Props) => {
    const notifications = React.useContext(NotificationsContext)
    const dispatch = useDispatch()

    const [pageIndex, setPageIndex] = React.useState(0);
    const loginState = useSelector(
        (appState: AppState) => appState.auth.loginState,
        (next, prev) => next.isEqual(prev));
    const loadDoctors = () => dispatch(_loadDoctors())
    const loadState = useSelector(
        (state: AppState) => state.domain.loadDoctorsState,
        (next, prev) => next.isEqual(prev));
    React.useEffect(() => {
        if (loadState.isEmpty && loginState.isSuccessful)
            loadDoctors();
    }, [loadState, loginState])

    const removeState = useSelector(
        (state: AppState) => state.domain.removeDoctorState,
        (next, prev) => next.isEqual(prev));
    const removeDoctor = (id: string) => {
        if (removeState.isInProgress)
            return
        if (confirm('Remove?'))
            dispatch(_removeDoctor(id))
    }
    const clearRemoveDoctorState = () => dispatch({
        type: DoctorActions.REMOVE_DOCTOR_STATE_CHANGED,
        state: AsyncState.empty(),
    })
    React.useEffect(() => {
        if (removeState.isFailed)
            notifications.get().addFromAsyncError(removeState.error)
        if (removeState.isSuccessful) {
            notifications.get().add({ message: 'Removed successfully', level: 'success' })
            clearRemoveDoctorState()
        }
    }, [removeState])

    return PickAsyncStateWidget(loadState, {
        onProgress: _ => <BackLoadingParagraph />,
        onFail: (error) => (
            <AsyncErrorMessage error={error}
                action={{ onClick: () => loadDoctors() }} />
        ),
        onValue: (_data) => {
            let data: Doctor[];
            if (!props.query) {
                data = _data;
            } else {
                const filter = props.query.toLowerCase()
                data = _data.filter(doctor =>
                    doctor.firstName.toLowerCase().includes(filter) ||
                    doctor.lastName.toLowerCase().includes(filter) ||
                    doctor.credentials.find(cred => cred.toLowerCase().includes(filter)) ||
                    doctor.specialties.find(spec => spec.toLowerCase().includes(filter)) ||
                    doctor.locations.find(location =>
                        location.name.toLowerCase().includes(filter) ||
                        location.address.toLowerCase().includes(filter) ||
                        location.phones.find(phone => phone.includes(filter)) ||
                        location.faxes.find(fax => fax.includes(filter))));
            }
            if (data.length / ITEMS_PER_PAGE < pageIndex) {
                setPageIndex(pageIndex - 1);
                return <Container />
            }
            if (data.length === 0)
                return (
                    <Segment basic placeholder>
                        <Header icon color='grey' size='tiny'>
                            <Icon name='search' />
                            <h4>Nothing was found</h4>
                        </Header>
                    </Segment>
                );
            const firstPageItem = pageIndex * ITEMS_PER_PAGE;
            const pageData = data.slice(firstPageItem, firstPageItem + ITEMS_PER_PAGE);
            return (
                <Container>
                    <Card.Group>
                        {pageData.map(doctor => (
                            <Card key={`doctorCard_${doctor.id}_${Math.random()}`}>
                                <Card.Content>
                                    <Image floated='right' size='mini'
                                        className='card_photo'
                                        src={doctor.photoUrl} />
                                    <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
                                        {!doctor.advanced ? null :
                                            <Icon name='star' color='blue' size='small' />}
                                        {doctor.firstName} {doctor.lastName}
                                    </Card.Header>
                                    <Card.Meta>{doctor.credentials.join(', ')}</Card.Meta>
                                    <Card.Description>
                                        <Container>
                                            {doctor.specialties.map((s, idx) =>
                                                <Label key={`speciality_${idx}`} className='badge'>
                                                    <Icon name='address card' />{s}
                                                </Label>)}
                                        </Container>
                                        <Container className='locations'>
                                            {doctor.locations.map((s, idx) =>
                                                <Label key={`location_${idx}`} className='badge'>
                                                    <Icon name='map marker' />{s.name}
                                                </Label>)}
                                        </Container>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <Button basic
                                        icon='trash alternate outline'
                                        color='red'
                                        size='mini'
                                        loading={removeState.isInProgress && removeState.value === doctor.id}
                                        onClick={() => removeDoctor(doctor.id)} />
                                    <Button basic
                                        color='green'
                                        size='mini'
                                        style={{ float: 'right' }}
                                        onClick={() => props.onEdit(doctor)}
                                    >Edit</Button>
                                </Card.Content>
                            </Card>
                        ))}
                    </Card.Group>
                    <Container className="pagination-box">
                        <Pagination
                            size='small'
                            activePage={pageIndex + 1}
                            totalPages={Math.ceil(data.length / ITEMS_PER_PAGE)}
                            onPageChange={(e, { activePage }) => setPageIndex((activePage as number) - 1)}
                        />
                    </Container>
                </Container>
            );
        },
    })
}