/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { AsyncError } from "../utils/async_state";

type ErrorMessage = { header?: string, message?: string }

export namespace I18N {
    export const tryAgain = 'Try again'

    export const fromAsyncError = (error: AsyncError): ErrorMessage => {
        switch (error.type) {
            case 'NETWORK_PROBLEMS':
                return {
                    header: 'Network Problems',
                    message: 'Check your internet connection',
                }
            case 'API_ERROR': {
                if (error.cause && error.cause.message)
                    return { message: error.cause.message }
                // else SERVER_ERROR
            }
            case 'SERVER_ERROR':
                return {
                    header: 'Server Error',
                    message: 'Try again later',
                }
        }
        return {
            header: 'Application Error',
            message: 'Contact system administrator',
        }
    }
}