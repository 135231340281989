/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */
import * as React from 'react';
import {
    Message, Segment, Image, Loader, Dimmer, Button, Icon,
    MessageProps,
} from 'semantic-ui-react'

import { AsyncState, AsyncError } from '../../utils/async_state';
import { I18N } from '../../i18n';


export function PickAsyncStateWidget<V>(state: AsyncState<V>, callbacks: {
    onFail?: (error: AsyncError) => JSX.Element,
    onProgress?: (value: V) => JSX.Element,
    onSuccess?: (value: V) => JSX.Element,
    onValue?: (value: V) => JSX.Element,
    orElse?: () => JSX.Element,
    onEmpty?: () => JSX.Element,
}): JSX.Element {
    if (state != null) {
        if (state.value != null && callbacks.onEmpty != null
            && (state.value as any).length === 0)
            return callbacks.onEmpty();
        if (state.value != null && callbacks.onValue != null)
            return callbacks.onValue(state.value);
        if (state.isSuccessful && callbacks.onSuccess != null)
            return callbacks.onSuccess(state.value);
        if (state.isInProgress && callbacks.onProgress != null)
            return callbacks.onProgress(state.value);
        if (state.isFailed && callbacks.onFail != null)
            return callbacks.onFail(state.error);
    }
    if (callbacks.orElse != null)
        return callbacks.orElse();
    return null
}

type AsyncErrorMessageProps = {
    error: AsyncError,
    pros?: MessageProps,
    action?: { text?: string, onClick: () => void },
}
export const AsyncErrorMessage = ({ error, pros, action }: AsyncErrorMessageProps) => {
    const content = I18N.fromAsyncError(error);
    return (
        <Message icon
            negative={error.sevirity === 'ERROR'}
            warning={error.sevirity === 'WARNING'}
            {...pros || {}}>
            <Icon name='warning circle' />
            <Message.Content>
                <Message.Header>{content.header}</Message.Header>
                {content.message}
            </Message.Content>
            {!action ? null :
                <Button basic color='grey' size='tiny'
                    onClick={action.onClick}>{action.text || I18N.tryAgain}</Button>
            }
        </Message>
    )
}

export const BackLoadingParagraph = () => (
    <Segment loading>
        <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
    </Segment>
)

export const BackLoading = () => (
    <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
    </Dimmer>
)