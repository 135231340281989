/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { AnyAction } from 'redux';

import { AsyncState } from '../utils/async_state';
import { AppState } from '.';
import { Doctor, Coordinates, ServiceProvider, Post, Condition } from '../models/domain.models';
import { DoctorActions } from '../actions/domain/doctor.actions';
import { ServiceProviderActions } from '../actions/domain/service-provider.actions';
import { PostActions } from '../actions/domain/post.actions';
import { ConditionActions } from '../actions/domain/condition.actions';

export type FindDoctorCoordinatesValue = {
    doctor: Doctor,
    locationIndex: number,
    coordinates: Coordinates,
}
export type FindServiceProviderCoordinatesValue = {
    provider: ServiceProvider,
    coordinates: Coordinates,
}

export class DomainState {
    readonly saveDoctorState: AsyncState<Doctor> = AsyncState.empty();
    readonly loadDoctorsState: AsyncState<Doctor[]> = AsyncState.empty();
    readonly removeDoctorState: AsyncState<string> = AsyncState.empty();
    readonly findDoctorCoordinatesState: AsyncState<FindDoctorCoordinatesValue> = AsyncState.empty();
    readonly saveServiceProviderState: AsyncState<ServiceProvider> = AsyncState.empty();
    readonly loadServiceProvidersState: AsyncState<ServiceProvider[]> = AsyncState.empty();
    readonly removeServiceProviderState: AsyncState<string> = AsyncState.empty();
    readonly findServiceProviderCoordinatesState: AsyncState<FindServiceProviderCoordinatesValue> = AsyncState.empty();
    readonly savePostState: AsyncState<Post> = AsyncState.empty();
    readonly loadPostsState: AsyncState<Post[]> = AsyncState.empty();
    readonly removePostState: AsyncState<string> = AsyncState.empty();
    readonly saveConditionState: AsyncState<Condition> = AsyncState.empty();
    readonly loadConditionsState: AsyncState<Condition[]> = AsyncState.empty();
    readonly removeConditionState: AsyncState<string> = AsyncState.empty();
}

export const domainReducer = (state: AppState, action: AnyAction): DomainState => {
    switch (action.type) {
        case DoctorActions.SAVE_DOCTOR_STATE_CHANGED: {
            const saveDoctorState = action.state as AsyncState<Doctor>
            let loadDoctorsState = state.domain.loadDoctorsState
            if (saveDoctorState.isSuccessful && loadDoctorsState.isSuccessful) {
                const doctor = saveDoctorState.value
                const newValue = loadDoctorsState.value.slice()
                const idx = newValue.findIndex(i => i.id === doctor.id)
                if (idx === -1) {
                    newValue.push(doctor)
                } else {
                    newValue[idx] = doctor
                }
                loadDoctorsState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                saveDoctorState,
                loadDoctorsState,
            }
        }
        case DoctorActions.LOAD_DOCTORS_STATE_CHANGED: {
            return {
                ...state.domain,
                loadDoctorsState: action.state,
            }
        }
        case DoctorActions.REMOVE_DOCTOR_STATE_CHANGED: {
            const removeDoctorState = action.state as AsyncState<string>
            let loadDoctorsState = state.domain.loadDoctorsState
            if (removeDoctorState.isSuccessful && loadDoctorsState.isSuccessful) {
                const id = removeDoctorState.value
                const newValue = loadDoctorsState.value.slice()
                const idx = newValue.findIndex(i => i.id === id)
                newValue.splice(idx, 1)
                loadDoctorsState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                removeDoctorState,
                loadDoctorsState,
            }
        }
        case DoctorActions.FIND_DOCTOR_COORDINATES_STATE_CHANGED: {
            return {
                ...state.domain,
                findDoctorCoordinatesState: action.state,
            }
        }
        case ServiceProviderActions.SAVE_SERVICE_PROVIDER_STATE_CHANGED: {
            const saveServiceProviderState = action.state as AsyncState<ServiceProvider>
            let loadServiceProvidersState = state.domain.loadServiceProvidersState
            if (saveServiceProviderState.isSuccessful && loadServiceProvidersState.isSuccessful) {
                const provider = saveServiceProviderState.value
                const newValue = loadServiceProvidersState.value.slice()
                const idx = newValue.findIndex(i => i.id === provider.id)
                if (idx === -1) {
                    newValue.push(provider)
                } else {
                    newValue[idx] = provider
                }
                loadServiceProvidersState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                saveServiceProviderState,
                loadServiceProvidersState,
            }
        }
        case ServiceProviderActions.LOAD_SERVICE_PROVIDERS_STATE_CHANGED: {
            return {
                ...state.domain,
                loadServiceProvidersState: action.state,
            }
        }
        case ServiceProviderActions.REMOVE_SERVICE_PROVIDER_STATE_CHANGED: {
            const removeServiceProviderState = action.state as AsyncState<string>
            let loadServiceProvidersState = state.domain.loadServiceProvidersState
            if (removeServiceProviderState.isSuccessful && loadServiceProvidersState.isSuccessful) {
                const id = removeServiceProviderState.value
                const newValue = loadServiceProvidersState.value.slice()
                const idx = newValue.findIndex(i => i.id === id)
                newValue.splice(idx, 1)
                loadServiceProvidersState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                removeServiceProviderState,
                loadServiceProvidersState,
            }
        }
        case ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED: {
            return {
                ...state.domain,
                findServiceProviderCoordinatesState: action.state,
            }
        }
        case PostActions.SAVE_STATE_CHANGED: {
            const savePostState = action.state as AsyncState<Post>
            let loadPostsState = state.domain.loadPostsState
            if (savePostState.isSuccessful && loadPostsState.isSuccessful) {
                const post = savePostState.value
                const newValue = loadPostsState.value.slice()
                const idx = newValue.findIndex(i => i.id === post.id)
                if (idx === -1) {
                    newValue.push(post)
                } else {
                    newValue[idx] = post
                }
                loadPostsState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                savePostState,
                loadPostsState,
            }
        }
        case PostActions.LOAD_STATE_CHANGED: {
            return {
                ...state.domain,
                loadPostsState: action.state,
            }
        }
        case PostActions.REMOVE_STATE_CHANGED: {
            const removePostState = action.state as AsyncState<string>
            let loadPostsState = state.domain.loadPostsState
            if (removePostState.isSuccessful && loadPostsState.isSuccessful) {
                const id = removePostState.value
                const newValue = loadPostsState.value.slice()
                const idx = newValue.findIndex(i => i.id === id)
                newValue.splice(idx, 1)
                loadPostsState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                removePostState,
                loadPostsState,
            }
        }
        case ConditionActions.SAVE_STATE_CHANGED: {
            const saveConditionState = action.state as AsyncState<Condition>
            let loadConditionsState = state.domain.loadConditionsState
            if (saveConditionState.isSuccessful && loadConditionsState.isSuccessful) {
                const condition = saveConditionState.value
                const newValue = loadConditionsState.value.slice()
                const idx = newValue.findIndex(i => i.id === condition.id)
                if (idx === -1) {
                    newValue.push(condition)
                } else {
                    newValue[idx] = condition
                }
                loadConditionsState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                saveConditionState,
                loadConditionsState,
            }
        }
        case ConditionActions.LOAD_STATE_CHANGED: {
            return {
                ...state.domain,
                loadConditionsState: action.state,
            }
        }
        case ConditionActions.REMOVE_STATE_CHANGED: {
            const removeConditionState = action.state as AsyncState<string>
            let loadConditionsState = state.domain.loadConditionsState
            if (removeConditionState.isSuccessful && loadConditionsState.isSuccessful) {
                const id = removeConditionState.value
                const newValue = loadConditionsState.value.slice()
                const idx = newValue.findIndex(i => i.id === id)
                newValue.splice(idx, 1)
                loadConditionsState = AsyncState.success(newValue);
            }
            return {
                ...state.domain,
                removeConditionState,
                loadConditionsState,
            }
        }
        default:
            return state.domain;
    }
}