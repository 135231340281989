/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */

export type AsyncStatus = 'IDLE' | 'IN_PROGRESS' | 'SUCCESS' | 'FAILED';
export type AsyncErrorType = 'NETWORK_PROBLEMS' | 'SERVER_ERROR' | 'APP_ERROR' | 'API_ERROR'
export type AsyncErrorSevirity = 'ERROR' | 'WARNING'

export class AsyncError extends Error {
    static networkProblems = () =>
        new AsyncError('NETWORK_PROBLEMS', 'WARNING');
    static serverError = (cause?: any) =>
        new AsyncError('SERVER_ERROR', 'ERROR', cause);
    static appError = () =>
        new AsyncError('APP_ERROR', 'ERROR');
    static apiError = (code: string, cause?: any) =>
        new ApiError(code, cause);

    readonly type: AsyncErrorType
    readonly sevirity: AsyncErrorSevirity
    readonly cause?: any

    constructor(type: AsyncErrorType, sevirity: AsyncErrorSevirity, cause?: any) {
        super();
        this.type = type
        this.sevirity = sevirity
        this.cause = cause
    }
}

export class ApiError extends AsyncError {
    readonly code: string;

    constructor(code: string, cause: any) {
        super('API_ERROR', 'WARNING', cause);
        this.code = code;
    }
}

export class AsyncState<V = void> {
    static empty<V>(value?: V): AsyncState<V> {
        return new AsyncState('IDLE', value);
    }
    static inProgress<V>(value?: V): AsyncState<V> {
        return new AsyncState('IN_PROGRESS', value);
    }
    static success<V>(value?: V): AsyncState<V> {
        return new AsyncState('SUCCESS', value);
    }
    static failed<V = any>(error: any, value?: V): AsyncState<V> {
        if (error instanceof AsyncError)
            return new AsyncState('FAILED', value, error);
        if (error.code && error.message)
            return new AsyncState('FAILED', value,
                AsyncError.apiError(error.code, error));

        console.error(error);
        if (error instanceof Error)
            console.log(error.stack);

        return new AsyncState('FAILED', value, AsyncError.appError());
    }

    readonly status: AsyncStatus;
    readonly value: V;
    readonly error: AsyncError;

    constructor(status: AsyncStatus, value?: V, error?: AsyncError) {
        this.status = status;
        this.value = value;
        this.error = error;
    }

    get isEmpty(): boolean { return this.status === 'IDLE' }
    get isInProgress(): boolean { return this.status === 'IN_PROGRESS' }
    get isNotInProgress(): boolean { return !this.isInProgress }
    get isSuccessful(): boolean { return this.status === 'SUCCESS' }
    get isNotSuccessful(): boolean { return !this.isSuccessful }
    get isFailed(): boolean { return this.status === 'FAILED' }
    get isUseless(): boolean { return this.isEmpty || this.isFailed }

    get hasValue(): boolean { return this.value != null }
    get hasNoValue(): boolean { return !this.hasValue }
    get isValueEmpty(): boolean { return this.value == null || this.value['length'] === 0 }
    get isValueNotEmpty(): boolean { return this.value && this.value['length'] > 0 }

    isEqual = (other: AsyncState<V>): boolean =>
        this.status === other.status && this.value === other.value
    isNotEqual = (other: AsyncState<V>): boolean => !this.isEqual(other)
}
