/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 * Freelancer: https://www.freelancer.com/u/brooth
 */
import * as React from 'react'
import * as NotificationSystem from 'react-notification-system'
import { Message } from 'semantic-ui-react'

import { AsyncError } from '../../utils/async_state';
import { I18N } from '../../i18n';


export interface NotificationConfig {
    position?: 'tr' | 'tl' | 'tc' | 'br' | 'bl' | 'bc'
    delay?: number
    dismissible?: boolean
    onDismiss?: () => void
}

export interface Notification extends NotificationConfig {
    title?: string
    message?: string
    level?: 'error' | 'warning' | 'info' | 'success'
}

export class Notifications extends React.Component {
    protected notifications: NotificationSystem.System

    add(notification: Notification): number {
        if (!notification.level)
            notification.level = 'warning'
        const config = {
            position: notification.position || 'tr',
            autoDismiss: notification.delay
                ? notification.delay / 1000
                : (notification.title + notification.message).length * .13,
            dismissible: notification.dismissible || true,
        }
        const uid = this.notifications.addNotification({
            level: notification.level,
            ...config,
            onRemove: (notif) => {
                if (notification.onDismiss && notif.uid === uid)
                    notification.onDismiss()
            },
            children: [
                <Message
                    key='notification'
                    negative={notification.level === 'error'}
                    warning={notification.level === 'warning'}
                    info={notification.level === 'info'}
                    positive={notification.level === 'success'}
                    icon={notification.level === 'error' ||
                        notification.level === 'warning' ?
                        'warning circle' : 'info circle'}
                    header={notification.title}
                    content={notification.message}
                    onDismiss={config.dismissible ? () => null : null}
                />,
            ],
        }).uid as number
        return uid
    }

    addFromAsyncError(error: AsyncError, config?: NotificationConfig): number {
        const i18n = I18N.fromAsyncError(error)
        return this.add({
            level: error.sevirity === 'ERROR' ? 'error' : 'warning',
            title: i18n.header,
            message: i18n.message,
            ...config || {},
        })
    }

    render() {
        return (
            <NotificationSystem
                ref={ref => this.notifications = ref}
                style={{
                    Containers: {
                        DefaultStyle: {
                            width: 380,
                            marginTop: '45px',
                        },
                    },
                    NotificationItem: {
                        DefaultStyle: {
                            padding: '0px',
                            borderTop: '',
                            backgroundColor: '',
                            boxShadow: '',
                        },
                    },
                }}
            />
        )
    }
}