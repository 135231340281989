/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import { ServiceProvider } from "../../models/domain.models";
import { AsyncAction } from "..";
import { AsyncState, AsyncError } from "../../utils/async_state";
import { GOOGLE_API_KEY } from '../../c';


export namespace ServiceProviderActions {
    export const SAVE_SERVICE_PROVIDER_STATE_CHANGED = '@domain/saveServiceProviderStateChanged'
    export const LOAD_SERVICE_PROVIDERS_STATE_CHANGED = '@domain/loadServiceProvidersStateChanged'
    export const REMOVE_SERVICE_PROVIDER_STATE_CHANGED = '@domain/removeServiceProviderStateChanged'
    export const FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED = '@domain/findServiceProviderCoordinatesStateChanged'
}

export const saveServiceProvider = (provider: ServiceProvider): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: ServiceProviderActions.SAVE_SERVICE_PROVIDER_STATE_CHANGED,
            state: AsyncState.inProgress(provider),
        })
        try {
            const ref = firebase.database().ref('service_providers');
            let value = provider
            if (provider.id) {
                await ref.child(provider.id).set(provider)
            } else {
                const newRef = await ref.push(provider)
                value = { ...provider, id: newRef.key }
            }

            dispatch({
                type: ServiceProviderActions.SAVE_SERVICE_PROVIDER_STATE_CHANGED,
                state: AsyncState.success(value),
            })
        } catch (e) {
            dispatch({
                type: ServiceProviderActions.SAVE_SERVICE_PROVIDER_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const laodServiceProviders = (): AsyncAction =>
    async (dispatch, getState, firebase) => {
        const currentValue = getState().domain.loadServiceProvidersState.value
        dispatch({
            type: ServiceProviderActions.LOAD_SERVICE_PROVIDERS_STATE_CHANGED,
            state: AsyncState.inProgress(),
        })
        try {
            const data = await firebase.database().ref('service_providers')
                .orderByKey().once('value')
            const providers = [] as ServiceProvider[]
            if (data.exists()) {
                data.forEach(snap => {
                    const provider = snap.val() as ServiceProvider
                    provider.id = snap.key
                    providers.push(provider)
                });
            }
            dispatch({
                type: ServiceProviderActions.LOAD_SERVICE_PROVIDERS_STATE_CHANGED,
                state: AsyncState.success<ServiceProvider[]>(providers),
            })
        } catch (e) {
            dispatch({
                type: ServiceProviderActions.LOAD_SERVICE_PROVIDERS_STATE_CHANGED,
                state: AsyncState.failed(e, currentValue),
            })
        }
    }

export const removeServiceProvider = (id: string): AsyncAction =>
    async (dispatch, _, firebase) => {
        dispatch({
            type: ServiceProviderActions.REMOVE_SERVICE_PROVIDER_STATE_CHANGED,
            state: AsyncState.inProgress(id),
        })
        try {
            await firebase.database().ref(`service_providers/${id}`).remove()
            dispatch({
                type: ServiceProviderActions.REMOVE_SERVICE_PROVIDER_STATE_CHANGED,
                state: AsyncState.success(id),
            })
        } catch (e) {
            dispatch({
                type: ServiceProviderActions.REMOVE_SERVICE_PROVIDER_STATE_CHANGED,
                state: AsyncState.failed(e),
            })
        }
    }

export const findServiceProviderCoordinates = (provider: ServiceProvider): AsyncAction =>
    async (dispatch, _, __) => {
        dispatch({
            type: ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED,
            state: AsyncState.inProgress({ provider }),
        })
        try {
            const query = encodeURIComponent(provider.location.address.replace(/\ /g, '+'));
            const uri = `https://maps.googleapis.com/maps/api/geocode/json` +
                `?key=${GOOGLE_API_KEY}&address=${query}`;
            const response = await fetch(uri)
            if (response.status !== 200) {
                dispatch({
                    type: ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED,
                    state: AsyncState.failed(AsyncError.serverError(), { provider }),
                })
                return;
            }
            const results = await response.json();
            if (results.results.length === 0) {
                dispatch({
                    type: ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED,
                    state: AsyncState.success({ provider }),
                })
                return;
            }
            dispatch({
                type: ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED,
                state: AsyncState.success({
                    provider,
                    coordinates: {
                        latitude: results.results[0].geometry.location.lat,
                        longitude: results.results[0].geometry.location.lng,
                    },
                }),
            })
        } catch (e) {
            dispatch({
                type: ServiceProviderActions.FIND_SERVICE_PROVIDER_COORDINATES_STATE_CHANGED,
                state: AsyncState.failed(e, { provider }),
            })
        }
    }