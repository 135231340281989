/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import * as React from 'react';
import { Modal, Header, Button, Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../store";
import { login } from '../../actions/auth.actions';
import { NotificationsContext } from '../../app';
import { DEV_MODE } from '../../c';

export const LoginModal = () => {
    const dispatch = useDispatch()
    const notifications = React.useContext(NotificationsContext)
    const [state, setState] = React.useState({
        email: DEV_MODE ? 'brooth@gmail.com' : '',
        password: DEV_MODE ? 'password' : '',
    })
    const loginState = useSelector(
        (appState: AppState) => appState.auth.loginState,
        (next, prev) => next.isEqual(prev));

    React.useEffect(() => {
        if (loginState.isFailed)
            notifications.get().addFromAsyncError(loginState.error)
        if (loginState.isSuccessful)
            notifications.get().add({
                message: `Logged in as ${loginState.value.displayName || loginState.value.email}`,
                level: 'success',
                delay: 1000,
            })
    }, [loginState])

    const doLogin = () => {
        if (state.email.length === 0)
            return notifications.get().add({ message: 'Empty email' })
        if (state.password.length === 0)
            return notifications.get().add({ message: 'Empty password' })

        dispatch(login(state.email, state.password))
    }

    return (
        <Modal basic size='mini'
            open={loginState.isNotSuccessful}>
            <Header icon='key' content='Sign In' />
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <input placeholder='Email'
                            value={state.email}
                            onChange={(e) => setState({
                                ...state,
                                email: e.target.value,
                            })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <input placeholder='Password'
                            type='password'
                            value={state.password}
                            onChange={(e) => setState({
                                ...state,
                                password: e.target.value,
                            })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='green'
                    loading={loginState.isInProgress}
                    disabled={loginState.isInProgress}
                    onClick={doLogin}>
                    Login
                </Button>
            </Modal.Actions>
        </Modal>
    )
}