/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance Software Development:
 * Upwork: https://www.upwork.com/fl/khalidovoleg
 */
import * as React from 'react'
import { Menu, Button, Input, Container, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';
import { logout as _logout } from '../../actions/auth.actions';
import { Doctor, ServiceProvider, Post, Condition } from '../../models/domain.models';
import { EditDoctorModal } from '../components/edit-doctor.modal';
import { ServiceProviderList } from '../components/service-provider-list';
import { DoctorList } from '../components/doctor-list';
import { EditServiceProviderModal } from '../components/edit-service-provider.modal';
import { PostList } from '../components/post-list';
import { EditPostModal } from '../components/edit-post.modal';
import { EditConditionModal } from '../components/edit-condition.modal';
import { ConditionList } from '../components/condition-list';

type Mode = 'doctors' | 'services' | 'posts' | 'conditions'

export const MainScreen = () => {
    const [state, setState] = React.useState({
        doctorListQuery: '',
        editDoctor: null as Doctor,
        serviceListQuery: '',
        editServiceProvider: null as ServiceProvider,
        postsQuery: '',
        editPost: null as Post,
        conditionsQuery: '',
        editCondition: null as Condition,
    })
    const [mode, setMode] = React.useState('doctors' as Mode)
    const logoutState = useSelector((appState: AppState) => appState.auth.logoutState);
    const dispatch = useDispatch()
    const logout = () => {
        if (confirm('Logout?'))
            dispatch(_logout())
    }
    const setQuery = (query: string) => {
        setState({
            ...state,
            doctorListQuery: mode === 'doctors' ? query : state.doctorListQuery,
            serviceListQuery: mode === 'services' ? query : state.serviceListQuery,
            postsQuery: mode === 'posts' ? query : state.postsQuery,
            conditionsQuery: mode === 'conditions' ? query : state.conditionsQuery,
        })
    }

    return (
        <Container>
            <EditDoctorModal
                doctor={state.editDoctor}
                onDissmiss={() => setState({ ...state, editDoctor: null })} />
            <EditServiceProviderModal
                provider={state.editServiceProvider}
                onDissmiss={() => setState({ ...state, editServiceProvider: null })} />
            <EditPostModal
                post={state.editPost}
                onDissmiss={() => setState({ ...state, editPost: null })} />
            <EditConditionModal
                condition={state.editCondition}
                onDissmiss={() => setState({ ...state, editCondition: null })} />
            <Menu borderless fixed='top'>
                <Menu.Item name='Doctors'
                    active={mode === 'doctors'}
                    onClick={_ => setMode('doctors')}
                />
                <Menu.Item name='Services'
                    active={mode === 'services'}
                    onClick={_ => setMode('services')}
                />
                <Menu.Item name='News'
                    active={mode === 'posts'}
                    onClick={_ => setMode('posts')}
                />
                <Menu.Item name='Conditions'
                    active={mode === 'conditions'}
                    onClick={_ => setMode('conditions')}
                />
                <Menu.Item style={{ flexGrow: 1 }}>
                    <Input
                        placeholder='Search...'
                        action={{
                            icon: 'cancel',
                            onClick: () => setQuery(''),
                        }}
                        value={mode === 'doctors'
                            ? state.doctorListQuery
                            : mode === 'services'
                                ? state.serviceListQuery
                                : mode === 'posts'
                                    ? state.postsQuery
                                    : state.conditionsQuery}
                        onChange={(_, data) => setQuery(data.value)}
                    />
                </Menu.Item>
                <Menu.Item position='right'>
                    {mode === 'doctors' ? (
                        <Button primary
                            onClick={() => setState({
                                ...state,
                                editDoctor: new Doctor(),
                            })}
                        ><Icon name='plus' />Doctor</Button>
                    ) : mode === 'services' ? (
                        <Button primary
                            onClick={() => setState({
                                ...state,
                                editServiceProvider: new ServiceProvider(),
                            })}
                        ><Icon name='plus' />Service Provider</Button>
                    ) : mode === 'posts' ? (
                        <Button primary
                            onClick={() => setState({
                                ...state,
                                editPost: new Post(),
                            })}
                        ><Icon name='plus' />Post</Button>
                    ) : (
                                    <Button primary
                                        onClick={() => setState({
                                            ...state,
                                            editCondition: new Condition(),
                                        })}
                                    ><Icon name='plus' />Condition</Button>
                                )}
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button negative
                        loading={logoutState.isInProgress}
                        disabled={logoutState.isInProgress}
                        onClick={logout}>Log Out</Button>
                </Menu.Item>
            </Menu>
            {
                mode === 'doctors' ? (
                    <DoctorList
                        query={state.doctorListQuery}
                        onEdit={(doctor) => setState({
                            ...state,
                            editDoctor: doctor,
                        })} />)
                    : mode === 'services' ? (
                        <ServiceProviderList
                            query={state.serviceListQuery}
                            onEdit={(provider) => setState({
                                ...state,
                                editServiceProvider: provider,
                            })}
                        />
                    ) : mode === 'posts' ? (
                        <PostList
                            query={state.postsQuery}
                            onEdit={post => setState({
                                ...state,
                                editPost: post,
                            })}
                        />
                    ) : (
                                <ConditionList
                                    query={state.postsQuery}
                                    onEdit={condition => setState({
                                        ...state,
                                        editCondition: condition,
                                    })}
                                />
                            )
            }
        </Container >
    )
}